import React from "react";
import "./TermsAndConditions.scss";
import { TermsAndConditionsData } from "../../Assets/Data/constants";

const TermsAndConditions = () => {
	return (
		<div className="TermsAndConditionsContainer">
			<h1>{TermsAndConditionsData?.Title}</h1>
			<h3>
				Effective Date: <span>{TermsAndConditionsData?.EffectiveDate}</span>{" "}
			</h3>
			<p>{TermsAndConditionsData?.desc}</p>

			<ol>
				{TermsAndConditionsData?.points?.map((a, index) => (
					<li className="itemContainer" key={index}>
						<h4>{a.title}</h4>

						{Array.isArray(a.desc) ? (
							a.desc.map((d, i) => (
								<div key={i}>
									{typeof d === "string" ? (
										<p>{d}</p>
									) : (
										<div>
											<h5>{d.subTitle}</h5>
											<ul>
												{d.SubList?.map((subItem, subIndex) => (
													<li key={subIndex}>{subItem}</li>
												))}
											</ul>
										</div>
									)}
								</div>
							))
						) : typeof a.desc === "string" ? (
							<p>{a.desc}</p>
						) : null}

						{a.list?.map((l, i) => (
							<div className="subListItems" key={i}>
								-
								<span className="subListItemsTitle">
									{l.title ? `${l.title}:` : ""}{" "}
								</span>
								{Array.isArray(l.desc) ? (
									l.desc.map((subItem, subIndex) => (
										<span key={subIndex}>
											<span>{subItem.subTitle}</span>
											<div className="descSubListItems">
												{subItem.SubList?.map((item, itemIndex) => (
													<p key={itemIndex}>-{item}</p>
												))}
											</div>
										</span>
									))
								) : (
									<span>{l.desc}</span>
								)}
							</div>
						))}
						<p>{a.descBottom}</p>
						{a.contact && <p>{a.contact.company}</p>}
						{a.contact && (
							<p>
								<span className="email">Email: </span>
								{a.contact.email}
							</p>
						)}
						{a.contact && (
							<p>
								<span className="phone">Phone: </span>
								{a.contact.phone}
							</p>
						)}
						{a.contact && (
							<p>
								<span className="address">Address: </span>
								{a.contact.address}
							</p>
						)}
					</li>
				))}
			</ol>
			<p>{TermsAndConditionsData?.footer?.divide}</p>
			<p>{TermsAndConditionsData?.footer?.desc}</p>
		</div>
	);
};

export default TermsAndConditions;
