import React from "react";
import "./InsightsPage.scss";
import { InsightsPageData } from "../../Assets/Data/constants";

const InsightsPage = () => {
	return (
		<div className="InsightsPageContainer">
			{InsightsPageData &&
				InsightsPageData.map((d, index) => (
					<div key={index} className="InsightItem">
						<div className="insightDetails">
							<h2>{d.title}</h2>
							<p>{d.desc}</p>
							<div className="ratingContainer">
								{d.rating?.map((r, index) => (
									<img key={index} src={r.img} alt="" />
								))}
							</div>
						</div>
						<img className="mainImg" src={d.img} alt="" />
					</div>
				))}
		</div>
	);
};

export default InsightsPage;
