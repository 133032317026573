import React, { useState } from "react";
import "./ContactPage.scss";
import { ContactPageData } from "../../Assets/Data/constants";
import EmailIcon from "../../Assets/email.svg";
import PhoneIcon from "../../Assets/call.svg";
import AddressIcon from "../../Assets/location.svg";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
const ContactPage = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault(); // Prevent form from refreshing the page
		const formData = {
			name,
			email,
			phone,
			message,
		};
		console.log("Form Data Submitted: ", formData);

		try {
			const response = await axios.post(
				`https://application.housingram.com/api/contact`,
				formData
			);
			console.log("data", response.data);

			// Reset form fields after successful submission
			setName("");
			setEmail("");
			setPhone("");
			setMessage("");

			// Show success toast message
			toast.success("Form submitted successfully!");
		} catch (error) {
			toast.error("Failed to submit form. Please try again later.");
		}
	};

	return (
		<div className="ContactPageContainer">
			<Toaster />
			<div className="item">
				<div className="details">
					<h1 className="title">Contact Us</h1>

					<h1 className="subtitle">Question? Get in touch!</h1>
					<div className="ContactItemsContainer">
						<div className="contactItem">
							<img src={EmailIcon} alt="" />
							<div>
								<h3>Email</h3>
								<p>{ContactPageData.email}</p>
							</div>
						</div>
						<div className="contactItem">
							<img src={PhoneIcon} alt="" />
							<div>
								<h3>Call Us</h3>
								<p>{ContactPageData.phone}</p>
							</div>
						</div>
						<div className="contactItem">
							<img src={AddressIcon} alt="" />
							<div className="address">
								<div>
									<h3>Official Address</h3>
									<p>{ContactPageData.officialAddress}</p>
								</div>
								<div>
									<h3>Secondary Address</h3>
									<p>{ContactPageData.secondaryAddress}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<form onSubmit={handleSubmit}>
					<div className="InputFields">
						<input
							type="text"
							placeholder="Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					<div className="InputFields doubleField">
						<input
							type="text"
							placeholder="Phone"
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
						/>
						<input
							type="email"
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>

					<div className="InputFields">
						<textarea
							rows={5}
							placeholder="Need of a solution"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
					</div>
					<button className="contactBtn" type="submit">
						Submit
					</button>
				</form>
			</div>
		</div>
	);
};

export default ContactPage;
