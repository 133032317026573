// HomePage.js
import React from "react";
import HeroSection from "../HeroSection/HeroSection";
import BannerPage from "../BannerPage/BannerPage";
import RealstatePage from "../RealstatePage/RealstatePage";
import InsightsPage from "../InsightsPage/InsightsPage";
import GetStarted from "../GetStarted/GetStarted";
import Testimonials from "../Testimonials/Testimonials";
import Layout from "../../Layout/Layout";

const HomePage = () => {
	return (
		<Layout>
			<HeroSection />
			<BannerPage />
			<RealstatePage />
			<InsightsPage />
			<Testimonials />
			<GetStarted />
		</Layout>
	);
};

export default HomePage;
