import React, { useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import "./SupportModal.scss";

const SupportModal = ({ setCloseModal }) => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault(); // Prevent form from refreshing the page
		const formData = {
			name,
			email,
			phone,
			message,
		};
		console.log("Form Data Submitted: ", formData);

		try {
			const response = await axios.post(
				`https://application.housingram.com/api/contact`,
				formData
			);
			console.log("data", response.data);

			// Reset form fields after successful submission
			setName("");
			setEmail("");
			setPhone("");
			setMessage("");

			// Show success toast message
			toast.success("Form submitted successfully!");
			setCloseModal(false);
		} catch (error) {
			toast.error("Failed to submit form. Please try again later.");
		}
	};
	return (
		<form onSubmit={handleSubmit}>
			<h1>Support</h1>
			<div className="InputFields">
				<input
					type="text"
					placeholder="Name"
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
			</div>
			<div className="InputFields doubleField">
				<input
					type="text"
					placeholder="Phone"
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
				/>
				<input
					type="email"
					placeholder="Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
			</div>

			<div className="InputFields">
				<textarea
					rows={5}
					placeholder="Message.."
					value={message}
					onChange={(e) => setMessage(e.target.value)}
				/>
			</div>
			<button className="contactBtn" type="submit">
				Submit
			</button>
		</form>
	);
};

export default SupportModal;
