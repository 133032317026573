import React from "react";
import "./PrivacyPage.scss";
import { PrivacyPageData } from "../../Assets/Data/constants";

const PrivacyPage = () => {
	return (
		<div className="PrivacyPageContainer">
			<h1>{PrivacyPageData?.mainTitle}</h1>
			<h3>
				Effective Date: <span>{PrivacyPageData?.effectiveDate}</span>
			</h3>
			{PrivacyPageData?.desc?.map((d, index) => (
				<div key={index}>
					<p>{d}</p>
				</div>
			))}
			<ol>
				{PrivacyPageData?.points?.map((p, index) => (
					<div key={index}>
						<li className="orderedTitles">
							<h3>{p.title}</h3>
						</li>
						<p style={{ marginTop: "0rem" }}>{p.desc}</p>
						{p.list?.map((l, subIndex) => (
							<div style={{ padding: "0.4rem 0rem" }} key={subIndex}>
								<span style={{ fontWeight: "700" }}>
									- {l.title ? <>{l.title} : </> : ""}
								</span>
								<span>{l.desc}</span>
							</div>
						))}
						<p>{p.bottomDesc}</p>
						{p.contact && <p>{p.contact.company}</p>}
						{p.contact && (
							<p>
								<span className="email">Email: </span>
								{p.contact.email}
							</p>
						)}
						{p.contact && (
							<p>
								<span className="phone">Phone: </span>
								{p.contact.phone}
							</p>
						)}
						{p.contact && (
							<p>
								<span className="address">Address: </span>
								{p.contact.address}
							</p>
						)}
					</div>
				))}
			</ol>
			<p>{PrivacyPageData?.footer?.divide}</p>
			<p>{PrivacyPageData?.footer?.desc}</p>
		</div>
	);
};

export default PrivacyPage;
