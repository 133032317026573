import React from "react";
import "./AboutPage.scss";
import { AboutPageData } from "../../Assets/Data/constants";

const AboutPage = () => {
	return (
		<div className="AboutPageContainer">
			<h1>{AboutPageData?.title}</h1>
			{AboutPageData?.desc?.map((a, index) => (
				<div key={index}>
					<p>{a}</p>
				</div>
			))}
		</div>
	);
};

export default AboutPage;
