// router.js
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import PrivacyPage from "./pages/PrivacyPage/PrivacyPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import Layout from "./Layout/Layout";
import ContactPage from "./pages/ContactPage/ContactPage";

function Router() {
	return (
		<div>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<HomePage />} />

					<Route path="/privacy" element={<PrivacyPage />} />
					<Route
						path="/about"
						element={
							<Layout>
								<AboutPage />
							</Layout>
						}
					/>
					<Route path="/terms" element={<TermsAndConditions />} />
					<Route
						path="/contact"
						element={
							<Layout>
								<ContactPage />
							</Layout>
						}
					/>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default Router;
