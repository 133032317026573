import React, {useState} from "react";
import "./GetStarted.scss";
import { GetStartedData } from "../../Assets/Data/constants";
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
const GetStarted = () => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("")
	const [message, setMessage] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault(); // Prevent form from refreshing the page
		const formData = {
			name,
			email,
			phone,
			message,
		};
		console.log("Form Data Submitted: ", formData);

		try {
			const response = await axios.post(`https://application.housingram.com/api/contact`, formData);
			console.log("data", response.data);
	  
			// Reset form fields after successful submission
			setName("");
			setEmail("");
			setMessage("");
	  
			// Show success toast message
			toast.success("Form submitted successfully!");
	  
		  } catch (error) {
			toast.error("Failed to submit form. Please try again later.");
		  }
	}
	return (
		<div id="getStarted" className="GetStartedContainer">
			<Toaster />
			<div className="item">
				<div className="details">
					<h1>{GetStartedData && GetStartedData.title}</h1>
					<img src={GetStartedData && GetStartedData.img} alt="" />
				</div>
				<form onSubmit={handleSubmit}>
					<div className="InputFields">
						<label htmlFor="Name">Name</label>
						<input
							type="text"
							placeholder="Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</div>
					<div className="InputFields">
						<label htmlFor="Name">Email</label>
						<input
							type="email"
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="InputFields">
						<label htmlFor="Name">Message</label>
						<textarea
							rows={5}
							placeholder="Need of a solution"
							value={message}
							onChange={(e) => setMessage(e.target.value)}
						/>
					</div>
					<button className="contactBtn" type="submit">
						Request Demo
					</button>
				</form>
			</div>
		</div>
	);
};

export default GetStarted;
